import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Button,
  Form,
  Icon,
  Popup,
  Modal,
  Loader,
  Message,
  Dimmer,
  Segment,
  Image
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  editStepDetail,
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  updateDefaultStep,
  updateStep,
  saveIsReviewer,
  resetDocumentMedia,
  setStepDetails,
  setStepMediaFiles,resetStepMediaFiles,
  setStepEdit,
  passMediaURL,
  removeStep,
  setBlankFields,
  showPopupAction,
  setFormEdit,
  removeNewStepIds,
  setStepFormEdit,
  resetEditStep,
  aiButtonon
} from "./../../redux/actions/index";
import {
  saveNewDocument,
  saveNewStep,
  saveEditedStep,
  updateDocument,
  uploadMedia,
  deleteSteps,
  getStepsByDocumentId,
  logUserAction
} from "../apiCall";
import FroalaEditor from "./FriolaEditor/FroalaEditor";
import ProgressBar from "@ramonak/react-progress-bar";
import ViewFeedback from "./ViewFeedback";
import useAutosave from "./useAutosave";
import KeycloakUserService from "../login/KeyCloakService";
import { appInsights } from "../utils/ustils";
import ChangeLog from "./ChangeLog";

const CreateInstructionsSteps = (props) => {
  //form is used for UI - to display data
  const [form, setForm] = useState(
    props.stepData?.description?.length > 0 || props.stepData?.title?.length > 0
      ? props.stepData
      : props.editStepData?.stepId?.length > 0
        ? props.editStepData
        : {
          title: "",
          description: "",
        }
  );

  //stepDataLocal is used for data management , get the stepId...
  const [stepDataLocal, setStepDataLocal] = useState(
    props.stepData?.description?.length > 0 || props.stepData?.title?.length > 0
      ? props.stepData
      : props.editStepData?.stepId?.length > 0
        ? props.editStepData
        : {
          title: "",
          description: "",
        }
  );
  const [prevFormVal, setPrevFormVal] = useState(
    props.editStepData?.stepId?.length > 0
      ? props.editStepData
      : {
        title: "",
        description: "",
      }
  );
  const [newStep, setNewStep] = useState(false);
  const [media, setMedia] = useState("");
  const [fileDetails, setFileDetails] = useState(
    props.stepMediaFiles?.fileDetails?.length > 0
      ? props.stepMediaFiles.fileDetails
      : []
  );

  const [editedFields, seteditedFields] = useState(
    props?.stepData?.changeLog
      ? props?.stepData?.changeLog
      : {
        title: "",
        description: "",
      }
  );

  const abortControllerRef = useRef();
  //to avoid unneccesary update doc API call
  const [statusUpdated , setStatusUpdated] = useState(false);
  const [changeLog, setShowChangeLog] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [dissablebuttons, setDissableButtons] = useState(false);
  const [uploadedMediaURL, setUploadedMedia] = useState();
  const [stepDescription, setStepDescription] = useState("");
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const [aiPopup, setAiPopup] = useState(false);
  const [aiGenerateddesc, setAiGenerateddesc] = useState("");
  const [confirmaidesc, setConfirmaidesc] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [aiModalloader, setAiModalloader] = useState(false);
  const [changeoccured, setCangeOccured] = useState(false);
  const [showPendingAlertForNoOption, setShowPendingAlertForNoOption] =
    useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [deleteFileCollection, setDeleteFileCollection] = useState(
    props.stepMediaFiles?.deleteFileCollection
      ? props.stepMediaFiles.deleteFileCollection
      : []
  );
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mediaFilesDownloaded, setMediaFilesDownloaded] = useState(
    props.stepMediaFiles?.mediaFilesDownloaded
      ? props.stepMediaFiles.mediaFilesDownloaded
      : props.editStepData && props.editStepData?.media
        ? props.editStepData.media
        : []
  );

  const [autosave, setAutosave] = useState(false);
  const [mediaToBeSaved, setMediaToBeSaved] = useState([]);
  const [feedbackCollection, setFeedbackCollection] = useState([]);
  const [enableCancelPopup, setEnableCancelPopup] = useState(false);
  const [stepContentChange, setStepContentChange] = useState(false);
  const [stepContentChange2, setStepContentChange2] = useState(false);
  const [changeInStepIsThere , setChangeInStepIsThere] = useState("");
  const [stopAnimation , setStopAnimation] = useState(false)
  const fileRef = useRef();
  const imageRef = useRef();
  let submitAlertClass = showSaveAlert ? "modal-alert animate" : "modal-alert";
  let submitAlertStyles =
    showSaveAlert || showPendingAlert
      ? {}
      : { opacity: 0, visibility: "hidden", pointerEvents: "none" };
  const showChangeLog = () => {
    setStopAnimation(true)
    setShowChangeLog((changeLog) => !changeLog);
  };
  /* Call this function when there is any change to the form */
  const handleChange = (e) => {
    setCangeOccured(true);
    setForm({ ...form, [e.target.name]: e.target.value });
    seteditedFields({
      ...editedFields,
      [e.target.name]: KeycloakUserService.getUsername(),
    });
    setStepContentChange(true);
    setStepContentChange2(true);
    props.setStepFormEdit(true);
  };
  useEffect(() => {

    if (props.stepData?.title?.length > 0) {
      setStepContentChange2(true);
    }
    if (stepContentChange) {

      props.setStepDetails(form);
    }
    if (props.viewFeedback) {
      setFeedbackVisible(true);
    }
  }, [form]);

  useEffect(() => {
    if (
      fileDetails?.length > 0 ||
      deleteFileCollection?.length > 0 ||
      mediaFilesDownloaded?.length > 0
    ) {
      const data = {
        fileDetails: fileDetails,
        deleteFileCollection: deleteFileCollection,
        mediaFilesDownloaded: mediaFilesDownloaded,
      };
      props.setStepMediaFiles(data);
    }
  }, [fileDetails, mediaFilesDownloaded, deleteFileCollection]);

  useEffect(() => {
    if (stepDescription.length > 0) {
      if (form.description !== stepDescription) {
        setStepContentChange(true);
        setStepContentChange2(true);
        seteditedFields({
          ...editedFields,
          description: KeycloakUserService.getUsername(),
        });
      }
      setForm({ ...form, ["description"]: stepDescription });

      setStepDescription("");
    }
    if (form.title === "" && stepContentChange2) {
      props.setBlankFields(true);
    } else {
      props.setBlankFields(false);
    }
  }, [stepDescription, form]);
  useEffect(() => {
    showToast &&
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
  }, [showToast]);
  useEffect(() => {
    setAiPopup(props.aimodal);
    logUserAction({
      documentId: props.procedureDetails.documentId, 
      action: 'ai-button'
    });
  }, [props.aimodal])
  useEffect(() => {
    // (form.stepId || localStorage.getItem("stepId").length) && fetchSteps();
    if (form.stepId) {
      localStorage.setItem("stepId", form.stepId);
    }
  }, []);
  useEffect(()=>{setChangeInStepIsThere(props.changeInStepIsThere)},[props.changeInStepIsThere])
  const handlecancelStep = async (e) => {
    const isStepIdPresent = props?.stepData?.stepId?.length > 0;
    const isNewStep = newStep || props.stepData.newStep;
    const isStepIdLocalPresent = stepDataLocal?.stepId;
    const isTitleEmpty = form.title === "";

    if (autosave || isStepIdPresent) {
      if (isNewStep) {
        setEnableCancelPopup(false);
        props.showPopupAction(false);
        setShowPendingAlertForNoOption(true);
        await deleteSteps(
          props.procedureDetails.documentId,
          isStepIdLocalPresent ? stepDataLocal.stepId : props?.stepData?.stepId
        );
        appInsights.trackEvent({
          name: "RemoveStep",
          properties: {
            documentId: props.procedureDetails.documentId,
            title: props.procedureDetails.procedureTitle,
            stepId: isStepIdLocalPresent ? stepDataLocal.stepId : props?.stepData?.stepId,
            step: isStepIdLocalPresent ? stepDataLocal.title : props?.stepData?.title,
            username: KeycloakUserService.getUsername(),
          },
        });
        props.removeStep(
          isStepIdLocalPresent ? stepDataLocal.stepId : props?.stepData?.stepId
        );
        props.history.goBack();
      } else if (isTitleEmpty && (isStepIdLocalPresent || isStepIdPresent)) {
        setEnableCancelPopup(false);
        props.showPopupAction(false);
        setShowPendingAlertForNoOption(true);
        updateStepDetails(true);
      } else if (saveInProgress) {
        setShowToast(true);
        setEnableCancelPopup(false);
        props.showPopupAction(false);
      } else if(form.stepId!==undefined){
   
        setEnableCancelPopup(false);
        props.showPopupAction(false);
        setShowPendingAlertForNoOption(true);
        updateStepDetails(true);
      } else{
     
        props.showPopupAction(false);
      e.preventDefault();
      props.history.goBack();
      }
    } else {
      props.showPopupAction(false);
      e.preventDefault();
      props.history.goBack();
    }
  };

  const renderUploadFile = (e) => {
    if (saveInProgress) {
      setShowToast(true);
    } else {
      e.preventDefault();
      e.stopPropagation();
      fileRef.current.click();
    }
  };

  const renderImageInsertFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    imageRef.current.click();
  };

  const getContent = useCallback(
    (data, type) => {
      setStepDescription(data);
      props.setStepFormEdit(true);
    },
    [form]
  );
  
useEffect(()=>{
  //to avoid doc update doc API call when the doc is in DRAFT status
  if(props.procedureDetails.status==="DRAFT")
    {
      setStatusUpdated(true)
    }
  },[])

  useAutosave(() => {
    setAutosave(true);
    !enableCancelPopup &&
      !saveInProgress &&
      stepContentChange2 &&
      handleSave(false, false, true);
  }, 10 * 1000);
const  changeInStatusForApprovedDoc = async()=>{
  //the status should be updated to doc havind approved status only and also for doc in in progrss taken in author account
  if(props.procedureDetails?.status && (props.procedureDetails?.status ==="APPROVED" || !(props.procedureDetails?.status ==="IN_PROGRESS" && props.isReviewer)))
    { 
       await updateDocumentstatus();
    }
}

  const handleSave = async (
    stepSave = false,
    navigate = true,
    autosave = false
  ) => {
    if (!autosave) {
      setEnableCancelPopup(false);
      props.showPopupAction(false);
    }

    if (form.title === "") {
      !autosave && setStepContentChange2(true);
    } else if (!saveInProgress) {
      setSaveInProgress(true);
      props.setStepEdit(true);
    
      if (props.procedureDetails.documentId === undefined) {
        if (stepDataLocal.stepId !== undefined) {
          await updateStepDetails();
        } else {
          // Save Document - this part will run when  intially the doc is not saved and step need to be saved
          const documentId = await saveNewDocumentData();
          await updateDefaultStepDetails(documentId);
        }
      } else if (!stepDataLocal?.stepId || !props?.stepData?.stepId?.length) {
       
          
        if (props.stepDetails[0].stepTitle === "Step Name") {
          await updateDefaultStepDetails(props.procedureDetails.documentId);
        } else {
          //save new step details

          localStorage.getItem("stepId").length
            ? await updateStepDetails()
            : await saveNewStepDeatails();
      
          // localStorage.getItem("stepId").length && await fetchSteps();
        }
      } else if (stepContentChange && stepDataLocal?.stepId) {
        //update step details
        await updateStepDetails();
      } else if (localStorage.getItem("stepId").length && !autosave) {
        await updateStepDetails();
      }
      //else an unwanted api call causes error during cancellation
      setAutosave(false)
      if (navigate) {
        setShowPendingAlert(false);
        setShowSaveAlert(true);
      }
      if (!stepSave && !autosave) {
        setForm({
          title: "",
          description: "",
        });
        seteditedFields({
          title: "",
          description: "",
        });
        setStepDataLocal({
          title: "",
          description: "",
        });
        props.setStepDetails({
          title: "",
          description: "",
        });
        setFileDetails([]);
        setShowChangeLog(false);
        setMediaFilesDownloaded([]);
        setStepContentChange(false);
        setStepContentChange2(false);
        localStorage.setItem("stepId", "");
        props.resetEditStep(true);
        props.resetStepMediaFiles();
      }

      if (stepSave && navigate) {
        props.resetEditStep(true);
        props.resetStepMediaFiles();
        setShowChangeLog(false);
        setTimeout(() => {
          props.history.goBack();
        }, 1000);
      } else {
        setTimeout(() => {
          setShowSaveAlert(false);
        }, 1000);
      }
      setSaveInProgress(false);
    } else {
      setShowToast(true);
    }
  };
  const handledraftAI = () => {
    setAiPopup(true);
  }
  const handleInputChangedesc = (e) => {
    setInputValue(e.target.value);
  };
  const fetchWithCancellation = async (controller,regenerate) => {
    try {
    const endpointUrl = "https://api.xauthorplus-dev.lexxtechnologies.com/generation";
    
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: form.procedureTitle,
        notes: inputValue,
        template: "vanoord",
      }),
      signal: controller.signal, // Attach the abort signal
    });
   
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    
    appInsights.trackEvent({
      name: "DraftWithAI",
      properties: {
        documentId: form.documentId,
        title: form.procedureTitle,
        notes: inputValue,
        template: "vanoord",
        username: KeycloakUserService.getUsername(),
        endpointUrl, endpointUrl,
        regenerate:regenerate
      },
    });
    const reader = response.body.getReader();
    let final = '';

    while (true) {
     
      const { done, value } = await reader.read();

      if (done  ) {
        break;
      }

      const decoder = new TextDecoder();
      const str = decoder.decode(value);
      final += str;
      setAiGenerateddesc(final);
    }
    setDissableButtons(false);
  }
  catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch was cancelled');
    } else {
      console.error('Fetch error:', error);
    }
  } 
};
  const handledraftaiapi = async (regenerate = false) => {
    setConfirmaidesc(true);
    setDissableButtons(true);
    setAiGenerateddesc("")
// Create a new AbortController
abortControllerRef.current = new AbortController();
// Start the fetch with the new controller
fetchWithCancellation(abortControllerRef.current,regenerate);
  }
  const handleStopStream = () => {    
    if (abortControllerRef.current) {
      setDissableButtons(false);
      // Cancel the fetch request
      abortControllerRef.current.abort();
    
    }
    
    };
  const handleConfirmaidesc = () => {
    getContent(aiGenerateddesc);
    props.aiButtonon(false);
    setConfirmaidesc(false);
    setInputValue("");
  }


  const updateDocumentstatus = async (isReviewer) => {
    const data = {
      procedureTitle: props.procedureDetails.procedureTitle,
      procedureDescription: props.procedureDetails.procedureDescription,
      documentType: props.template,
      status:props.isReviewer ? "IN_PROGRESS":"DRAFT",
      category: props.procedureDetails.category,
    };
    const formData = createFormDocumentData(data);
    await updateDocument(formData, props.procedureDetails.documentId)
      .then((response) => {
        setStatusUpdated(true);
        const ignoreStatus = ['DRAFT', 'IN_PROGRESS'];
        if (ignoreStatus.includes(data.status) == false) {
          appInsights.trackEvent({
            name: "EditDocument",
            properties: {
              documentId: data.documentId,
              title: data.procedureTitle,
              status: data.status,
              username: KeycloakUserService.getUsername(),
            },
          });
        }
       })
      .catch((err) => {
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
  };

  // Save procedure data
  const saveNewDocumentData = async () => {
    let documentId = "";
    const data = {
      procedureTitle: props.procedureDetails.procedureTitle,
      procedureDescription: props.procedureDetails.procedureDescription,
      documentType: props.template,
      status: "DRAFT",
      category: props.procedureDetails.category,
    };
    const formData = createFormDocumentData(data);
    await saveNewDocument(formData).then((response) => {
      response.data.data.media.forEach((file) => {
        if (file.fileName) {
          const ext = file.fileName.split(".").pop();
          file.extention = ext;
        }
      });
      props.setProcedureDetails(response.data.data);
      props.resetDocumentMedia(true);
      documentId = response.data.data.documentId;
    });
    return documentId;
  };

  // Create formdata to save doc
  const createFormDocumentData = (doc) => {
    const formData = new FormData();
    let docDetail = {
      procedureTitle: doc.procedureTitle,
      procedureDescription: doc.procedureDescription,
      documentType: doc.documentType,
      status: doc.status,
      category: doc.category,
    };
    formData.append("data", JSON.stringify(docDetail));
    if (props.docMedia.fileDetails && props.docMedia.fileDetails.length > 0) {
      for (let i = 0; i < props.docMedia.fileDetails.length; i++) {
        formData.append("media", props.docMedia.fileDetails[i]);
      }
    }
    return formData;
  };

  const getNewMedia = () => {
    const newMediaFileName = mediaFilesDownloaded
      .map((media) => {
        const isUniqueFileName = prevFormVal.media.every((prevMedia) => {
          if (prevMedia.fileName !== media.fileName) {
            return media.fileName;
          }
        });

        if (isUniqueFileName) {
          return media.fileName;
        }

        return null; // or any default value if needed
      })
      .filter(Boolean); // Filter out null values

    return newMediaFileName;
  };
  // Update step details
  const updateStepDetails = async (isPrevVal = false) => {

    !statusUpdated && changeInStatusForApprovedDoc();
    var data = {
      title: isPrevVal ? prevFormVal.title : form.title,
      description: isPrevVal ? prevFormVal.description : form.description,
      order_id: isPrevVal
        ? prevFormVal.order_id
        : stepDataLocal.order_id
          ? stepDataLocal.order_id
          : localStorage.getItem("orderId"),
      stepId: localStorage.getItem("stepId"),
      uploadedMedia: isPrevVal ? prevFormVal.media : mediaToBeSaved,
      mediaToBeDeleted: isPrevVal ? getNewMedia() : deleteFileCollection,
      editedFields: isPrevVal
        ? props.prevFormVal?.changeLog
          ? props.prevFormVal?.changeLog
          : []
        : editedFields,
    };
    // Update the existing step with edited values
    const formData = createFormData(data, true);
    await saveEditedStep(
      props.procedureDetails.documentId
        ? props.procedureDetails.documentId
        : localStorage.getItem("documentId"),
      formData,
      stepDataLocal.stepId
        ? stepDataLocal.stepId
        : localStorage.getItem("stepId")
    )
      .then((response) => {
        data.media = response.data.data.media;

      
        props.setStepDetails(response.data.data);
        if (response.data.data?.media?.length > 0) {
          setMediaFilesDownloaded(getMediaFiles(response.data.data.media));
        }

        mediaToBeSaved.length > 0 && setMediaToBeSaved([]);
        setStepContentChange(false);
        props.updateStep(response.data.data);
        isPrevVal && props.history.goBack();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
      
  };
  //Save new step details
  const updateDefaultStepDetails = async (documentId) => {
    setNewStep(true);
    !statusUpdated && changeInStatusForApprovedDoc();
    if (form.title.length > 0) {
      var data = {
        title: form.title,
        description: form.description,
        order_id: 0,
        tableData: [],
        stepId: "",
        uploadedMedia: mediaToBeSaved,
      };
      const formData = createFormData(data);
      await saveNewStep(formData, documentId).then((response) => {
        data.stepId = response.data.data.steps.stepId;
        //saved in local storage to persist the id while refreshing the page.
        localStorage.setItem("stepId", response.data.data.steps.stepId);
        localStorage.setItem("orderId", data.order_id);
        const result = response.data.data.steps;

        setMediaFilesDownloaded(getMediaFiles(response.data.data.steps.media));
        props.setStepDetails(data);
        setStepDataLocal(data);
        mediaToBeSaved.length > 0 && setMediaToBeSaved([]);

        props.updateDefaultStep(result);
        setStepContentChange(false);
      });
     // fetchSteps();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const getMediaFiles = (media) => {
    media.forEach((file) => {
      file.extention = file.fileName.split(".").pop();
    });
    setFileDetails([]);
    return media;
  };

  const saveNewStepDeatails = async () => {
    !statusUpdated && changeInStatusForApprovedDoc();
    setNewStep(true);
    if (form.title.length > 0) {
      var data = {
        title: form.title,
        description: form.description,
        order_id: props.stepDetails.length,
        stepId: "",
        uploadedMedia: mediaToBeSaved,
      };
      const formData = createFormData(data);
      await saveNewStep(formData, props.procedureDetails.documentId).then(
        (response) => {
          const result = response.data.data.steps;

          data.stepId = response.data.data.steps.stepId;
          appInsights.trackEvent({
            name: "NewStep",
            properties: {
              documentId: props.procedureDetails.documentId,
              title: props.procedureDetails.procedureTitle,
              stepId: data.stepId,
              step: data.title,
              username: KeycloakUserService.getUsername(),
            },
          });
          props.addStep(response.data.data.steps)
          //saved in local storage to persist the id while refreshing the page.
          localStorage.setItem("stepId", response.data.data.steps.stepId);
          localStorage.setItem("orderId", data.order_id);
          props.setStepDetails(data);
          setStepDataLocal(data);
          setMediaFilesDownloaded(
            getMediaFiles(response.data.data.steps.media)
          );
          setStepContentChange(false);
          mediaToBeSaved.length > 0 && setMediaToBeSaved([]);
        }
      );
    }
  };

  // Create formdata to save step
  const createFormData = (step, isEditstep = false) => {

    const formData = new FormData();
    const uploadedMedia = step.uploadedMedia;
    let stepDetail;
    if (isEditstep) {
      stepDetail = {
        stepTitle: step.title,
        description: step.description,
        order_id: step.order_id,
        _id: step.stepId,
        mediaToBeDeleted: step.mediaToBeDeleted,
        editedFields: step.editedFields,
      };
    } else {
      stepDetail = {
        stepTitle: step.title,
        description: step.description,
        order_id: step.order_id,
        stepId: "",
        tableData: [],
      };
    }
    formData.append("data", JSON.stringify(stepDetail));
    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let i = 0; i < uploadedMedia.length; i++) {
        formData.append("media", uploadedMedia[i]);
      }
    }
    return formData;
  };

  // Set Uploaded files
  const handleInputChange = async (e, type) => {
    setStepContentChange(true);
    setStepContentChange2(true);
    e.preventDefault();
    const fileData = e.target.files;
    if (type === "video") {
      for (let i = 0; i < fileData.length; i++) {
        if (validateFile(fileData[i])) {
          if (!fileData[i].name) {
            const binary = atob(fileData[i].split(",")[1]);
            const array = [];
            var file;
            for (let i = 0; i < binary.length; i += 1) {
              array.push(binary.charCodeAt(i));
            }
            file = new Blob([new Uint8Array(array)], {
              type: "image/png",
            });
            file.name = `${new Date()}.png`;
            file.lastModifiedDate = new Date();
            const myFile = new File([file], file.name, {
              type: file.type,
            });
            setFileDetails((fileDetails) => [...fileDetails, myFile]);
            setMediaToBeSaved((fileDetails) => [...fileDetails, myFile]);
          }
          setFileDetails((fileDetails) => [...fileDetails, fileData[i]]);
          setMediaToBeSaved((fileDetails) => [...fileDetails, fileData[i]]);
        }
      }
    } else {
      const formFile = await getformData(fileData);
      setShowPendingAlert(true);
      await uploadMedia(formFile).then((res) => {
        setUploadedMedia(res.data.data.url);
        setShowPendingAlert(false);
        setFileUpload(true);
        props.passMediaURL(res.data.data.url);
        setShowSaveAlert(true);
        setTimeout(() => {
          setShowSaveAlert(false);
          setFileUpload(false);
        }, 1000);
      });
    }
  };

  const getformData = async (file) => {
    const formData = new FormData();
    formData.append("media", file[0]);
    return formData;
  };
  const getUrl = () => {
    navigator.clipboard.writeText(uploadedMediaURL);
    setUploadedMedia();
    setFileDetails([]);
  };

  const validateFile = (fileData) => {
    const fileType = fileData.name.split(".").pop().toLowerCase();
    if (fileData.size > 100 * 1024 * 1024) {
      const fileSize = (fileData.size / 1024 / 1024).toFixed(2);
      alert(
        "The attached file (" +
        fileSize +
        ") exceeds the maximum size limit of 100 MB. Please reduce the file size and try again."
      );
      setLoading(false);
      return false;
    } else if (!(fileType === "mov" || fileType === "mp4")) {
      alert(
        "We do not accept files of this media type. Only file with mp4 and mov are allowed."
      );
      setLoading(false);
      return false;
    } else {
      const isFileExist = fileDetails?.some(
        (file) => file.name === fileData.name
      );
      const isFileAlreadySaved = mediaFilesDownloaded?.some(
        (file) => file.fileName.split("/").pop() === fileData.name
      );
      if (isFileExist || isFileAlreadySaved) {
        alert(
          "The file you are trying to upload has the same name as an existing file. Please rename the file or choose a different one."
        );
        setLoading(false);
        return false;
      }
    }
    return true;
  };

  // Remove files
  const removeFile = (fileName, collectionName) => {
    setStepContentChange(true);
    setStepContentChange2(true);
    const fileUpdatedCollection = [];
    switch (collectionName) {
      case "fileDetails":
        if (fileDetails.length > 0) {
          for (let i = 0; i < fileDetails.length; i++) {
            if (fileDetails[i].name !== fileName) {
              fileUpdatedCollection.push(fileDetails[i]);
            }
          }
          setFileDetails(fileUpdatedCollection);
        }
        break;
      case "media":
        if (mediaFilesDownloaded) {
          mediaFilesDownloaded.forEach((media) => {
            if (media.fileName !== fileName) {
              fileUpdatedCollection.push(media);
            } else {
              setDeleteFileCollection((deleteFileCollection) => [
                ...deleteFileCollection,
                media.fileName,
              ]);
            }
          });
          setMediaFilesDownloaded(fileUpdatedCollection);
        }
        break;
      default:
        break;
    }
  };
  const mediaFullScreen = (media) => {
    setMedia(media);
  };

  const getTransitionTime = () => {
    if (fileDetails.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*"
        ) {
          return "40s";
        }
        return "3s";
      }
    } else if (fileDetails.length > 5) {
      return "10s";
    }
    return "2s";
  };

  const renderImagesAndVideos = () => {
    var rendreFiles = [];
    // Downloaded files
    if (mediaFilesDownloaded) {
      mediaFilesDownloaded.forEach((media) => {
        if (
          media.extention === "png" ||
          media.extention === "PNG" ||
          media.extention === "jpeg" ||
          media.extention === "JPEG" ||
          media.extention === "jpg" ||
          media.extention === "JPG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    className="photo"
                    src={media.url}
                    typeof="image/png, image/jpeg, image/jpg"
                    alt=""
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  />
                }
                position="left center"
              >
                {media.fileName.split("/")[2]}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
        if (
          media.extention === "mp4" ||
          media.extention === "MP4" ||
          media.extention === "MOV" ||
          media.extention === "mov"
        ) {

          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  >
                    <source className="photo" src={media.url} alt="" />
                  </video>
                }
                position="left center"
              >
                {media.fileName.split("/")[2]}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
      });
    }
    // Uploaded files
    if (fileDetails?.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "image/png" ||
          fileDetails[i].type === "image/jpeg" ||
          fileDetails[i].type === "image/jpg" ||
          fileDetails[i].type === "image/JPG" ||
          fileDetails[i].type === "image/JPEG" ||
          fileDetails[i].type === "image/PNG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    src={URL.createObjectURL(fileDetails[i])}
                    className="photo"
                    typeof="image/png, image/jpeg, image/jpg, image/*, JPEG/H.264"
                    alt=""
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                  />
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(fileDetails[i].name, "fileDetails")}
              >
                X
              </button>
            </div>
          );
        } else if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*" ||
          fileDetails[i].type === "HEVC/H.264" ||
          fileDetails[i].type === "hevc/h.264"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    preload="metadata"
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                  >
                    <source
                      className="photo"
                      src={URL.createObjectURL(fileDetails[i])}
                      alt=""
                    />
                  </video>
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(fileDetails[i].name, "fileDetails")}
              >
                X
              </button>
            </div>
          );
        }
      }
    }
    return rendreFiles;
  };

  const renderFeedback = (data) => {
    return <ViewFeedback stepId={stepDataLocal.stepId} feedback={data} />;
  };
  useEffect(() => {
    mediaToBeSaved.length > 0 && handleSave(false, false, true);
    mediaToBeSaved.length === 0 && setLoading(false);
  }, [mediaToBeSaved]);
  const handleVideoUpload = (e) => {
    setLoading(true);
    handleInputChange(e, "video");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    handleFeedbackData();
  }, [props.feedbackData]);

  const viewFeedbackData = () => {
    handleFeedbackData();
    setFeedbackVisible(true);
  };

  const handleFeedbackData = () => {
    var feedbackArray = [];

    props.feedbackData.forEach((feedback) => {
      let stepID =
        stepDataLocal.stepId !== undefined
          ? typeof stepDataLocal.stepId === "string"
            ? stepDataLocal.stepId
            : stepDataLocal.stepId[0]
          : "";
      if (feedback.stepId[0] === stepID) {
        for (let i = 0; i < feedback.media.length; i++) {
          const ext = feedback.media[i].fileName.split(".")[1];
          feedback.media[i].ext = ext;
        }
        feedbackArray.push(feedback);
      }
    });

    setFeedbackCollection(feedbackArray);
  };
  const closehandler = (e) => {
    if (saveInProgress) {
      setShowToast(true);
    } else if (form.title === "") {
      setEnableCancelPopup(true);
    } else {
      stepContentChange2 ? setEnableCancelPopup(true) : handlecancelStep(e);
    }
  };

  return (
    <div className="session-summary">
      <Modal open={loading}>
        <Loader active>Video is processing...</Loader>
      </Modal>
      {changeLog && props?.editStepData?.lastApprovedVersion?.stepId && (
        <ChangeLog
          visible={changeLog}
          setShowChangeLog={setShowChangeLog}
          form={form}
          steps={true}
        />
      )}

      {media.type === "image/png" ||
        media.type === "image/jpeg" ||
        media.type === "image/jpg" ||
        media.type === "image/JPG" ||
        media.type === "image/JPEG" ||
        media.type === "image/PNG" ||
        media.extention === "png" ||
        media.extention === "PNG" ||
        media.extention === "jpeg" ||
        media.extention === "JPEG" ||
        media.extention === "jpg" ||
        media.extention === "JPG" ? (
        <Modal className="modal-upload-image" open={media}>
          <button onClick={() => setMedia("")} className="image-close">
            X
          </button>
          <div className="modal-action-image">
            <img
              className="image-fullscreen"
              src={
                media.type !== undefined
                  ? URL.createObjectURL(media)
                  : media.url
              }
              alt="image"
            />
          </div>
        </Modal>
      ) : (
        <Modal className="modal-upload-image" open={media}>
          <button onClick={() => setMedia("")} className="image-close">
            X
          </button>
          <div className="modal-action-image">
            <video preload="metadata" className="image-fullscreen" controls>
              <source
                className="photo"
                src={
                  media.type !== undefined
                    ? URL.createObjectURL(media)
                    : media.url
                }
                alt=""
              />
            </video>
          </div>
        </Modal>
      )}
        <div className="feedback-btn-wrap">
              <Popup
              style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
              trigger={
                props?.editStepData?.lastApprovedVersion?.stepId && <Button className={`cancelbtn ${changeInStepIsThere===props.editStepData?.stepId && !stopAnimation?"changeLog-btn":"nochangeLog-btn"}`} onClick={() => showChangeLog()}>
                    Change log
                  </Button>
              
              }
              position="top left"
            >
               Click here to see the changes in Step Title, Step Description, and Media.
            </Popup>
            {props.isFromFeedback && (      <Popup
              style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
              trigger={
                <Button className="secondory-btn"  onClick={() => viewFeedbackData()}>
                  View Feedbacks
                </Button>
              }
              position="top center"
            >
              View Feedbacks
            </Popup> )}
          </div>
      <Form>




        <h3 style={{ backgroundColor: "#F5F5F5", marginTop: "1.25rem" }}>
          Detailed Step <p style={{ display: "flex", float: "right" }}>  Autosaving in every 10 seconds.</p>
        </h3>
        <div className={feedbackVisible ? "step-feedback-wrap" : ""}>
          <div
            id="step-wrapper"
            className={feedbackVisible ? "step-wrapper" : ""}
          >
            <Form.Field
              required
              className={feedbackVisible ? "" : "input-wrap"}
            >
              <p className="form-label">Step title *</p>
              <input
                name="title"
                placeholder="Step title in here"
                className="instruction-input"
                value={form.title}
                onChange={handleChange}
                maxLength="250"
              />

              {form.title === "" && stepContentChange2 && (
                <span style={{ color: "red" }}>
                  Step Title is a mandatory field.
                </span>
              )}
            </Form.Field>
            <Form.Field
              required
              className={feedbackVisible ? (form.title !== "" ? "" : "ai-button-disable") : (form.title !== "" ? "input-wrap" : "input-wrap ai-button-disable")}
            >
              <div className="desc-wrapup">
                <p className="form-label" style={{ paddingBottom: "8px" }}>What would you do for this step?</p>
                {/* {props.featureFlag?.['draft-with-ai'] && (<Button className={form.title !== "" ? "ai-button" : "ai-button-disable"} onClick={form.title !== "" && handledraftAI}><Icon name="microchip" />Ai</Button>)} */}
              </div>
              <FroalaEditor
                content={form.description}
                getContent={getContent}
              />
            </Form.Field>
            <div className="myContainer">
              <div className="take">
                <Button
                  className="savebtn"
                  onClick={renderUploadFile}
                  disabled={!form.title}
                >
                  Attach Video
                </Button>
                <Popup
                  style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                  trigger={
                    <Icon
                      name="exclamation circle"
                      className="exclamation-icon"
                    />
                  }
                  position="right center"
                >
                  Only file with mp4 and mov are allowed
                </Popup>
                <input
                  type="file"
                  id="video"
                  className="takefile"
                  name="media"
                  multiple
                  accept="video/mp4, JPEG/H.264, MOV, mov"
                  ref={fileRef}
                  onChange={(e) => {
                    setCangeOccured(true);
                    handleVideoUpload(e);
                  }}
                />
              </div>
              <div>
                {fileDetails && (
                  <div className="image-wrap">{renderImagesAndVideos()}</div>
                )}
              </div>
            </div>
          </div>
          {feedbackVisible && !changeLog && (
            <div className="view-feedback" style={{ height: "660px" }}>
              <Icon name="close" onClick={() => setFeedbackVisible(false)} />
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                {feedbackCollection.length > 0 && <div className="feedback-header">Feedback</div>}
              </div>
              {feedbackCollection.length > 0 ? feedbackCollection.map((data) => renderFeedback(data)) : <h2 className="text-no-feedback">No Feedback</h2>}
            </div>
          )}
        </div>
      </Form>
      <div className="buttons">
        {!props.isFromFeedback && (
          <Popup
            style={{
              fontSize: "10px",
              background: "rgb(242 247 251)",
              width: "160px",
            }}
            trigger={
              <Button
                className="savebtn"
                onClick={() => handleSave(false)}
                disabled={
                  form.title.length === 0 || showPendingAlert || showSaveAlert
                }
              >
                Save and New
              </Button>
            }
            position="top center"
          >
            Save step and start new step
          </Popup>
        )}
        <Popup
          style={{
            fontSize: "10px",
            background: "rgb(242 247 251)",
            width: "120px",
          }}
          trigger={
            <Button
              className="savebtn"
              onClick={(e) => handleSave(e, true)}
              disabled={
                form.title.length === 0 || showPendingAlert || showSaveAlert
              }
            >
              Save and Return
            </Button>
          }
          position="top center"
        >
          Save step and return
        </Popup>{" "}
        <Popup
          style={{
            fontSize: "10px",
            background: "rgb(242 247 251)",
            width: "80px",
          }}
          trigger={
            <Button
              className="cancelbtn"
              onClick={(e) => closehandler(e)}
              disabled={showPendingAlert || showSaveAlert}
            >
              Cancel
            </Button>
          }
          position="top center"
        >
          Cancel step
        </Popup>
      </div>
      {showPendingAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant={"warning"}
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/pending-shape.png")}
              alt=""
            />
          </div>
          <div className="model-alert-msg">
            <h4 className="Heading">
              {fileUpload
                ? "File upload in progress"
                : "Step saving in progress"}
            </h4>
            <p className="Para">
              {fileUpload
                ? "Please wait until file upload completed"
                : "Please wait while saving is completed"}
            </p>
            <ProgressBar
              completed={90}
              bgColor="#08bd5c"
              labelColor="#1e1818"
              transitionDuration={getTransitionTime()}
              transitionTimingFunction="ease-in"
              animateOnRender
              customLabel=" "
              height=""
            />
          </div>
        </div>
      )}
      <div>
        <Modal open={showPendingAlertForNoOption}>
          <Loader active>Loading...</Loader>
        </Modal>
      </div>
      {showToast && (
        <div className="toast  ">
          <Message color="red" size="large">
            <Message.Header>Message</Message.Header>
            <p>
              Autosave in progress! Please hold for a moment and do it again.
            </p>
          </Message>
        </div>
      )}
      {showSaveAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant={"success"}
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/Shape 1.png")}
              alt=""
            />
          </div>
          <div className="model-alert-msg">
            <h4 className="Heading">
              {fileUpload ? "File Uploaded" : "Step Saved"}
            </h4>
            <p className="Para">
              {fileUpload
                ? "Now you can copy the media url"
                : "You can edit it again later"}
            </p>
            <ProgressBar
              completed={100}
              bgColor="#08bd5c"
              labelColor="#1e1818"
              customLabel=" "
              height=""
            />
          </div>
        </div>
      )}
      {
       aiPopup && (
          <Modal
            className="modal-upload-ai"
            open={aiPopup}
            onClose={() => {
              props.aiButtonon(false)
              setInputValue("")
            }}
          >
            {!confirmaidesc ? (<>
              {!aiModalloader ? (<><div className="modal-heading-ai">
              <h4>Generate with LexX</h4>
                  <div className="info-button">
                  <div className="popup-info-ai">
                        Please provide detailed key points to enable context-specific content generation.
                      </div>
                  </div>
                <button onClick={() => {
                  props.aiButtonon(false)
                  setInputValue("")
                }}>X</button>
              </div>
                <div className="wrap-input-ai">
                  <div className="keyword-wrap">
                    <p className="heading-keyword">Key Points<span style={{ color: "red", padding: "2px" }}>*</span></p>
                  </div>
                  <textarea
                    className="input-ai"
                    type="text"
                    placeholder="Eg Data : 
                    Create a Echoscope Document I need Information on:
                    Hardware Overview
                    Echoscope Sonar Head
                    Current Voltage Requirement
                    What is the 3D Productivity Station (3DPS)"
                    value={inputValue}
                    onChange={handleInputChangedesc}
                  />
                </div>
                <div className="generate-draft">
                  <Button primary className={/^\s*$/.test(inputValue) ? "generate-draft-button ai-button-disable" : "generate-draft-button"} onClick={()=>handledraftaiapi(false)} >Generate</Button>
                  
                </div></>) : (<Segment className="loader-ai">
                  <Dimmer active inverted>
                    <Loader inverted content='Loading' />
                  </Dimmer>
                </Segment>)}
            </>
            ) : (<>
              {!aiModalloader ? (<><div className="confirm-modal-ai">
                <div className="regenerate-button-div">
                  <Button className={dissablebuttons ? "regenerate-button ai-button-disable" : "regenerate-button"} onClick={()=>handledraftaiapi(true)}><Icon name="redo" />Regenerate</Button>
                  <Button primary onClick={()=>{handleStopStream()}} disabled={!dissablebuttons}> Stop Generating</Button>
                </div>
                <div className="ai-generated-desc-confirm">{aiGenerateddesc}</div>

              </div>
                <div className={dissablebuttons ? "wrap-up-ai-buttons ai-button-disable" : "wrap-up-ai-buttons"}>
                  <Button primary onClick={() => {  setConfirmaidesc(false)  }} > Back</Button>
                  <Button primary onClick={handleConfirmaidesc} > Done </Button>
                </div></>) : (<Segment className="loader-ai">
                  <Dimmer active inverted>
                    <Loader inverted content='Loading' />
                  </Dimmer>
                </Segment>)}
            </>)}
          </Modal>
        )
      }
      {(enableCancelPopup || props.showPopupRedux) && (
        <Modal
          className="modal-upload-delete"
          open={enableCancelPopup || props.showPopupRedux}
          onClose={() => {
            setEnableCancelPopup(false);
            props.showPopupAction(false);
          }}
        >
          <div className="modal-heading-delete">
            <div>
              <h3>Confirm Cancel</h3>
              <p>Do you want to save step?</p>
            </div>

            <button
              style={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={() => {
                setEnableCancelPopup(false);
                props.showPopupAction(false);
              }}
            >
              X
            </button>
          </div>
          <div className="modal-action-delete">
            <Button
              className="modal-button-option-del2"
              onClick={(e) => handleSave(e, true)}
            >
              Yes
            </Button>
            <Button
              className="modal-button-option-del1"
              onClick={(e) => handlecancelStep(e)}
            >
              No
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    stepDetails: state.stepDetails,
    template: state.template,
    editStep: state.editStep,
    editStepData: state.editStepData,
    procedureDetails: state.procedureDetails,
    isReviewer: state.isReviewer,
    docMedia: state.docMedia,
    stepData: state.stepData,
    stepMediaFiles: state.stepMediaFiles,
    feedbackData: state.feedbackData,
    viewFeedback: state.viewFeedback,
    isFromFeedback: state.isFromFeedback,
    blankField: state.blankField,
    showPopupRedux: state.showPopupRedux,
    featureFlag: state.featureFlag,
    changeInStepIsThere:state.changeInStepIsThere,
    aimodal: state.aimodal,
  };
};

export default connect(mapStateToProps, {
  addStep,
  removeStep,
  resetEditStep,
  setSelectedTemplate,
  setEnableEditStep,
  editStepDetail,
  setProcedureDetails,
  updateDefaultStep,
  updateStep,
  saveIsReviewer,
  resetDocumentMedia,
  setStepDetails,
  setStepMediaFiles,
  resetStepMediaFiles,
  setStepEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  setFormEdit,
  addStep,
  updateDefaultStep,
  removeNewStepIds,
  setStepFormEdit,
  aiButtonon
})(CreateInstructionsSteps);
