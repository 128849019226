import React, { useState } from "react";
import { Button, Menu, Modal, Accordion, Popup, Icon } from "semantic-ui-react";
import AllSteps from "./Container";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import {
  addStep,
  setProcedureDetails,
  resetEditStepIds,
  saveIsReviewer,
  saveDocumentfeedback,
  saveDocumentMedia,
  mediafiletorender,
} from "../../redux/actions";
import { connect } from "react-redux";
import { updateDocument, saveNewDocument } from "./../apiCall";
import { isMobile } from "react-device-detect";
import { HTML5Backend } from "react-dnd-html5-backend";
import { saveEditedStep, updateFeedbackStatus } from "./../apiCall";
import ProcedureDiscriptionView from "./PreviewCard";
import ProgressBar from "@ramonak/react-progress-bar";
import KeycloakUserService from "../login/KeyCloakService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { appInsights } from "../utils/ustils";

const Preview = (props) => {
  const location = useLocation();
  const [media, setMedia] = useState("");
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertPendingMessage, setAlertPendingMessage] = useState();
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [dropDown, setDropDown] = useState(false);
  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    if (props.isReviewer) {
      handleSubmitDocument();
    } else {
      setShowConfirmSubmit(true);
    }
  };

  const handleSubmitDocument = async () => {
    setShowConfirmSubmit(false);
    setAlertMessage({
      alertHeader: props.isReviewer
        ? "Document Published"
        : "Document Submitted",
      alertDescription: props.isReviewer
        ? "Document is now published and available to all VO users"
        : "Document is submitted for review.",
    });
    setAlertPendingMessage({
      alertHeader: props.isReviewer
        ? "Document publishing in progress"
        : "Document submitting in progress",
      alertDescription: props.isReviewer
        ? "Please wait while publishing is completed"
        : "Please wait while submitting is completed",
    });
    setShowPendingAlert(true);
    await updateDocumentSteps();


    const data =
      props.procedureDetails.documentId === undefined
        ? {
            procedureTitle: props.procedureDetails.procedureTitle,
            procedureDescription: props.procedureDetails.procedureDescription,
            documentType: props.template,
            status: props.isReviewer ? "APPROVED" : "AWAITING_REVIEW",
            category: props.procedureDetails.category,
            mediaToBeDeleted: props.docMedia.mediaToBeDeleted,
            uploadedMedia: props.docMedia.fileDetails,
          }
        : {
            procedureTitle: props.procedureDetails.procedureTitle,
            procedureDescription: props.procedureDetails.procedureDescription,
            documentType: props.template,
            status: props.isReviewer ? "APPROVED" : "AWAITING_REVIEW",
            category: props.procedureDetails.category,
            mediaToBeDeleted: props.docMedia.mediaToBeDeleted,
            uploadedMedia: props.docMedia.fileDetails,
            editedFields: props.procedureDetails.changeLog,
          };

    // await updateDocument(data, props.procedureDetails.documentId);
    const formData = createFormDocumentData(data);
    if (props.procedureDetails.documentId === undefined) {
      await saveNewDocument(formData).then((response) => {
        data.documentId = response.data.data.documentId;
        props.setProcedureDetails(data);
      });
    } else {
      await updateDocument(formData, props.procedureDetails.documentId)
        .then((response) => {
          data.documentId =
            response.data.data.documentId === undefined
              ? response.data.data.documents.documentId
              : response.data.data.documentId;
          const ignoreStatus = ['DRAFT', 'IN_PROGRESS'];
          if (ignoreStatus.includes(data.status) == false) {
            appInsights.trackEvent({
              name: "EditDocument",
              properties: {
                documentId: data.documentId,
                title: data.procedureTitle,
                status: data.status,
                username: KeycloakUserService.getUsername(),
              },
            });
          }
          props.setProcedureDetails(data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            KeycloakUserService.doLogout();
          }
        });
    }
    setShowPendingAlert(false);
    setShowSaveAlert(true);
    setTimeout(() => {
      props.history.push("/");
    }, 1000);
  };
  const handlecancelStep = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const handleSaveDraft = async () => {
    setAlertMessage({
      alertHeader: "Document Saved",
      alertDescription: "You can edit it again later",
    });

    setAlertPendingMessage({
      alertHeader: "Document saving in progress",
      alertDescription: "Please wait while saving is completed",
    });
    setShowPendingAlert(true);
    await updateDocumentSteps();
    await updateDocumentData();
    setShowPendingAlert(false);
    setShowSaveAlert(true);
    setTimeout(() => {
      props.history.push("/");
    }, 1000);
  };

  const updateDocumentData = async () => {
    const data = {
      procedureTitle: props.procedureDetails.procedureTitle,
      procedureDescription: props.procedureDetails.procedureDescription,
      status: props.isReviewer ? "IN_PROGRESS" : "DRAFT",
      category: props.procedureDetails.category,
      documentType: props.template,
      uploadedMedia: props.docMedia.fileDetails,
      mediaToBeDeleted: props.docMedia.mediaToBeDeleted,
      editedFields: props.procedureDetails.changeLog,
    };
    const formData = createFormDocumentData(data);
    if (props.procedureDetails.documentId === undefined) {
      await saveNewDocument(formData).then((response) => {
        data.documentId = response.data.data.documentId;
        props.setProcedureDetails(data);
      });
    } else {
      await updateDocument(formData, props.procedureDetails.documentId)
        .then((response) => {
          data.documentId =
            response.data.data.documentId === undefined
              ? response.data.data.documents.documentId
              : response.data.data.documentId;
          const ignoreStatus = ['DRAFT', 'IN_PROGRESS'];
          if (ignoreStatus.includes(data.status) == false) {
            appInsights.trackEvent({
              name: "EditDocument",
              properties: {
                documentId: data.documentId,
                title: data.procedureTitle,
                status: data.status,
                username: KeycloakUserService.getUsername(),
              },
            });
          }
          props.setProcedureDetails(data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            KeycloakUserService.doLogout();
          }
        });
    }
  };

  // Create formdata to save doc
  const createFormDocumentData = (doc) => {
 
    const formData = new FormData();
    const uploadedMedia = doc.uploadedMedia;
    let docDetail = {
      procedureTitle: doc.procedureTitle,
      procedureDescription: doc.procedureDescription,
      documentType: doc.documentType,
      status: doc.status,
      category: doc.category,
      mediaToBeDeleted: doc.mediaToBeDeleted,
      editedFields: doc.editedFields,
    };
    formData.append("data", JSON.stringify(docDetail));
    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let i = 0; i < uploadedMedia.length; i++) {
        formData.append("media", uploadedMedia[i]);
      }
    }
    return formData;
  };

  const updateDocumentSteps = async () => {
    //Save steps aganist existing solution
    if (props.stepDetails.length > 0) {
      await saveStepsByDocument();
      props.resetEditStepIds(true);
    }
  };

  const sortSteps = (steps) => {
    steps.sort(function (a, b) {
      return a.order_id - b.order_id;
    });
  };
  const mediaFullScreen = (media) => {
    setMedia(media);
  };
  // Get unique steps Ids
  const getUniqueIds = (editStepIds) => {
    return editStepIds?.filter(
      (item, index) => editStepIds.indexOf(item) === index
    );
  };
  const onClickSection = (e, props) => {
    setDropDown(!dropDown);
    let sectionIndex = props.index;

    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;

    setActiveIndex(newActiveIndex);
  };
  // Create formdata to save step
  const createFormData = (step, isEditstep = false) => {
    const formData = new FormData();
    let stepDetail = {
      stepTitle: step.stepTitle,
      description: step.stepDescription,
      order_id: step.order_id,
      _id: step.stepId,
    };
    formData.append("data", JSON.stringify(stepDetail));
    return formData;
  };

  // Save steps by solution id
  const saveStepsByDocument = async () => {
    sortSteps(props.stepDetails);
    if (props.editedStepIds) {
      const editedStepIdCollection = props.editedStepIds;
      const editedStepIds = getUniqueIds(editedStepIdCollection);
      for (let i = 0; i < props.stepDetails.length; i++) {
        const step = props.stepDetails[i];
        for (let i = 0; i < editedStepIds.length; i++) {
          const stepId = editedStepIds[i];
          if (step.stepId === stepId) {
            const formData = createFormData(step, true);
            await saveEditedStep(
              props.procedureDetails.documentId,
              formData,
              stepId
            ).catch((err) => {
              if (err.response.status === 403) {
                KeycloakUserService.doLogout();
              }
            });
          }
        }
      }
    }
  };

  let submitAlertClass = showSaveAlert ? "modal-alert animate" : "modal-alert";
  let submitAlertStyles =
    showSaveAlert || showPendingAlert
      ? {}
      : { opacity: 0, visibility: "hidden", pointerEvents: "none" };

  return (
    <div>
      {showPendingAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant={"warning"}
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/pending-shape.png")}
              alt=""
            />
          </div>
          <div className="model-alert-msg">
            <h4 className="Heading">{alertPendingMessage.alertHeader}</h4>
            <p className="Para">{alertPendingMessage.alertDescription}</p>
            <ProgressBar
              completed={90}
              bgColor="#08bd5c"
              labelColor="#1e1818"
              transitionDuration="3s"
              transitionTimingFunction="ease-in"
              animateOnRender
              customLabel=" "
              height=""
            />
          </div>
        </div>
      )}
      {showSaveAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant="success"
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/Shape 1.png")}
              alt=""
            />
          </div>

          <div className="model-alert-msg">
            <h4 className="Heading">{alertMessage.alertHeader}</h4>
            <p className="Para">{alertMessage.alertDescription}</p>
            <ProgressBar
              completed={100}
              bgColor="#08bd5c"
              labelColor="#1e1818"
              customLabel=" "
              height=""
            />
          </div>
        </div>
      )}
      <div className="preview-summary">
        <div className="main-heading">
          <h2>Preview</h2>
        </div>
        <h4>Procedure title</h4>
        <p>{props.procedureDetails.procedureTitle}</p>
        <h4>Category</h4>
        <p>{props.procedureDetails.category}</p>
        <ProcedureDiscriptionView
          description={props.procedureDetails.procedureDescription}
          media={props.mediarender}
          fileDetails={props.docMedia.fileDetails}
          mediaFullScreen={mediaFullScreen}
        />
        {props.stepDetails[0].stepTitle !== "Step Name" && (
          <>
            <br></br>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={onClickSection}
              >
                Table of contents
                <div className="wrap-icons">
                  <Popup
                    style={{
                      fontSize: "10px",
                      background: "rgb(242 247 251)",
                    }}
                    trigger={
                      <Icon
                        name="chevron down"
                        index={1}
                        className="dropdown-icon action-icon"
                        style={{
                          transform: dropDown && "rotate(180deg)",
                          fontSize: "1.2em",
                        }}
                      />
                    }
                    position="top center"
                  >
                    {activeIndex !== 0 ? "Expand Content" : "Collapse Content"}
                  </Popup>
                </div>
              </Accordion.Title>

              <Accordion.Content
                active={activeIndex === 0}
                className="marginL2"
              >
                <div>
                  <ol>
                    {props.stepDetails.map((steps) => (
                      <li>{steps.stepTitle}</li>
                    ))}
                  </ol>
                </div>
              </Accordion.Content>
            </Accordion>
          </>
        )}
        {props.stepDetails[0].stepTitle !== "Step Name" && (
          <>
            <h4>Steps of procedure </h4>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              {props.stepDetails.length > 0 && (
                <AllSteps
                  steps={props.stepDetails}
                  isPreview="true"
                  disableReorder={false}
                />
              )}
            </DndProvider>
          </>
        )}
      </div>
      <div className="buttons">
        <>
          <Button
            className="savebtn"
            onClick={(e) => {
              handleSaveDraft(e);
            }}
            disabled={showPendingAlert || showSaveAlert}
          >
            Save Draft
          </Button>
          <Button
            className="savebtn"
            onClick={(e) => handleConfirmSubmit(e)}
            disabled={showPendingAlert || showSaveAlert}
          >
            {props.isReviewer ? "Publish" : "Submit"}
          </Button>
        </>

        <Button
          className="cancelbtn"
          disabled={showPendingAlert || showSaveAlert}
          onClick={(e) => handlecancelStep(e)}
        >
          Back
        </Button>
      </div>
      {showConfirmSubmit && (
        <Modal
          className="modal-upload-delete"
          open={showConfirmSubmit}
          onClose={() => setShowConfirmSubmit(false)}
        >
          <div className="modal-heading-delete">
            <div>
              <h3>Confirm Document Submit</h3>
              <p>
                Once this document is submitted, it cannot be edited or modified
                until the review process is completed
              </p>
              <p>Are you sure you want to continue?</p>
            </div>
            <button
              onClick={() => setShowConfirmSubmit(false)}
              style={{ marginRight: "1rem", marginBottom: "5.4rem" }}
            >
              X
            </button>
          </div>
          <div className="modal-action-delete">
            <Button
              className="modal-button-option-del1"
              onClick={() => setShowConfirmSubmit(false)}
            >
              No
            </Button>
            <Button
              className="modal-button-option-del2"
              onClick={() => handleSubmitDocument()}
            >
              Yes
            </Button>
          </div>
        </Modal>
      )}
      {media && (
        <Modal className="modal-upload-image" open={media}>
          <button onClick={() => setMedia("")} className="image-close">
            X
          </button>
          <div className="modal-action-image">
            <img className="image-fullscreen" src={media} alt="" />
          </div>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    procedureDetails: state.procedureDetails,
    stepDetails: state.stepDetails,
    template: state.template,
    editedStepIds: state.editedStepIds,
    isReviewer: state.isReviewer,
    feedbackData: state.feedbackData,
    isFromFeedback: state.isFromFeedback,
    docMedia: state.docMedia,
    mediarender: state.mediarender,
  };
};

export default connect(mapStateToProps, {
  setProcedureDetails,
  addStep,
  resetEditStepIds,
  saveIsReviewer,

  saveDocumentfeedback,
  saveDocumentMedia,
})(Preview);
